import { useRemoveProfileRoleMutation } from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation removeProfileRole($input: RemoveProfileRoleInputType!) {
    removeProfileRole(input: $input)
  }
`;

export default function () {
  const mutation = useRemoveProfileRoleMutation({});

  function call(profileId: string, roleId: string) {
    return mutation.mutate({
      input: {
        profileId: profileId,
        roleId: roleId,
      },
    });
  }

  return {
    ...mutation,
    call,
  };
}
